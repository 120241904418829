
import { useStore } from "vuex";
import { defineComponent, computed, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
export default defineComponent({
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const setbank = computed(() => {
      return store.state.WalletWithdraw.withdrawdetail;
    });
    async function init() {
      store.commit("setLoadingGlobal", true);
      await store.dispatch(
        `WalletWithdraw/${Actions.FETCH_BANK_DETAIL}`,
        route.params.withdraw_id
      );
      store.commit("setLoadingGlobal", false);
    }
    onMounted(() => {
      init();
    });
    return {
      router,
      setbank,
      isLoading,
    };
  },
});
